.helpCenterContent {
  z-index: 1;
  min-height: 150vh;
  width: 100%;
}

.videoThumbnail {
  position: absolute;
  width: 200px;
  height: 100px;
  opacity: 0.6;
}

.QAContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.question {
  color: #015e88;
  font-size: 20px;
  font-weight: 700;
  overflow-wrap: anywhere;
  padding: 10px 5px;
  text-align: left;
}

.answer {
  color: #7f7f7f;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  overflow-wrap: anywhere;
}

.answer a {
  color: #0075aa;
  text-decoration: underline;
}

.keyboardArrowDownIcon {
  fill: #0075aa !important;
  transform: rotate(180deg);
  display: block;
}

.keyboardArrowUpIcon {
  fill: #0075aa !important;
  display: block;
}

.global {
  font-family: Roboto, sans-serif;
}

.container {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 10px;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
}

.gradientTitle {
  background: -webkit-linear-gradient(90deg, #620000 30%, #9e2339 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 24px;
  padding-left: 10px;
  margin-left: 10px;
}

.questionsContainer {
  background-color: white;
  border-radius: 12px;
  padding: 32px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.questionItemContainer {
  border: none;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid grey;
}

.headerContainer {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 10px;
  justify-content: space-between;
}

.headerFiltersContainer {
  display: flex;
  align-items: center;
  min-height: 32px;
  justify-content: space-between;
  border-radius: 12px;
  padding: 12px 10px 0 0;
}

.titleQuickReferenceCard {
  margin-left: 10px;
  background: -webkit-linear-gradient(90deg, #620000 30%, #9e2339 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 24px;
  padding-left: 10px;
}

.header {
  display: flex;
  align-items: center;
}

.fileHeader {
  font-weight: 700;
  font-size: 20px;
  color: #015e88;
  padding-left: 20px;
  padding-top: 15px;
  min-height: 45px;
}

.filterItem {
  display: inline-flex;
  padding: 10px;
}

.files {
  border-radius: 0 12px 12px 12px;
  background-color: white;
  margin-bottom: 30px;
  min-height: 235px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 25%);
}

.fileItem {
  display: inline-flex;
  padding: 20px;
  width: 230px;
}

.chipItem {
  font-size: 16px;
}

.cardItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: center;
  background-color: #f5f5f5;
}

.mainCard {
  width: 200px;
}

.wrapperCardItem {
  box-shadow: 0 2px 6px rgb(0 0 0 / 25%);
}

.wrapperButtonItem {
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.downloadFile {
  padding: 5px 0;
  text-decoration: none;
  color: #015e88;
  font-weight: 700;
  overflow-wrap: anywhere;
}

.downloadFileVideoIcon {
  padding-left: 10px;
}

.videoIcon {
  z-index: 10;
}

.globalReleaseNote {
  font-family: Roboto, sans-serif;
  margin-bottom: 30px;
}

.mainContainer {
  background-color: white;
  border-radius: 12px;
  padding: 32px;
  margin-bottom: 15px;
}

.itemContainer {
  border: none;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid grey;
  text-align: left;
}

.releaseNoteContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleName {
  color: #015e88;
  font-weight: 700;
  font-size: 20px;
  padding: 20px 5px;
}

.containerMain {
  padding: 24px;
  display: flex;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
}

.menu {
  background-image: linear-gradient(#189ad5, #0075aa);
  width: 176px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 12px;
}

.menuItem {
  margin: 10px;
  text-decoration: none;
}

.menuItem:hover {
  background: linear-gradient(180deg, #0075aa 0%, #004d82 100%);
  text-align: left;
}

.leftContent {
  margin-right: 24px;
  position: sticky;
  height: fit-content;
  flex: 0 0 233px;
}

.content {
  min-width: 0;
  flex: 1;
  padding-bottom: 100px;
}

.text {
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
}


.noRecords {
  padding: 32px;
  overflow-wrap: anywhere;
}

.cardIconContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 190px !important;
  justify-content: flex-end;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0) !important;
  padding: 3px 3px !important;
}

.cardIcon {
  margin-left: 3px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.cardIconButton {
  height: 16px !important;
  width: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 3px 3px !important;
  border: none !important;
  fill: #0075aa !important;
  font-size: 16px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  cursor: pointer !important;
}
.emptySection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptySectionQRC {
  min-height: 235px;
}

.emptySectionLabel {
  font-weight: 700;
}

.noKeywordMatch {
  font-weight: 400;
  font-size: 24px;
  color: #7f7f7f;
  overflow-wrap: break-word;
}

.adjustKeywords {
  font-size: 16px;
  color: #bdbdbd;
}

.emptySection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptySectionQRC {
  min-height: 235px;
}

.emptySectionLabel {
  font-weight: 700;
}
