:root {
  --bodyWidth: 100%;
}

html,
body {
  height: 100%;
  background-color: #E5E5E5;
}

#root {
  height: 100%;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: column;
}
input.MuiInputBase-input, div.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 16px;
}

main {
  flex: auto;
}

.errorBorder {
  border: 1px solid #E00000 !important;
}

.errorMessage {
  color: #E00000 !important;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  position: absolute;
}

.styledErrorMessage {
  color: #E00000 !important;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  position: relative;
}

.dateValidation {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dateValidation:hover {
  width: 100%;
}

.errorMessageBigRed {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 10px;
  left: 0px;
  top: 3px;
  width: 100%;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  z-index: 99;
  text-align: left;
}

.errorMessageBigRedFloating {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 3px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  margin-left: -40%;
  z-index: 99;
  text-align: left;
  width: 190px;
}

.errorMessageBigRedFloating210 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 3px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  margin-left: -40%;
  z-index: 99;
  text-align: left;
  width: 210px;
}

.errorMessageBigRedFloatingLabel {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 23px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  margin-left: -40%;
  z-index: 99;
  text-align: left;
  width: 190px;
}

.errorMessageBigRedFloatingCoInsurance {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 3px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  /* float: left; */
  margin-bottom: -100%;
  margin-left: -150px;
  margin-top: -8px;
  z-index: 99;
  text-align: left;
  max-width: 190px;
}

.MuiSelect-root.MuiSelect-select.MuiInput-input {
  padding-right: 30px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 90% !important;
  }
}

#spinner {
  position: absolute;
  z-index: 99;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editableSegmentControl {
  width: 100%;
  font-size: 18px;
  display: inline-flex;
  position: relative;
  flex-direction: column;
}

.editableSegmentControl > label {
  padding-left: 12px;
  color: #adadad;
  z-index: 1;
  font-weight: 400;
  line-height: 1;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 28px;
  left: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.editableSegmentControl > label.shrink {
  transform: translate(0, -2px) scale(0.7777777777777778);
  margin-left: -12px;
  top: 0;
  color: black;
  padding-left: unset;
  visibility: visible;
  opacity: 1;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, opacity 200ms;
}

.segmentInputAccordion .tooltip-x {
  display: none;
}

.segmentInputAccordion .tooltip-x > div {
  color: rgba(0, 0, 0, 0.87);
  border: 1px;
  font-size: 14px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-color: #f2f2f2;
  background-color: #ffffff;
}

.segmentInputAccordion .tooltip-x > div .MuiTooltip-arrow {
  color: #fff;
  background-color: transparent;
}

.segmentInputAccordion.showTooltip .tooltip-x {
  display: block;
}

.segmentContainer::before {
  content: attr(data-placeholder);
  color: #adadad;
}

.segmentContainer:focus {
  text-overflow: clip;
}

.segmentContainer:not(:focus) {
  text-overflow: ellipsis;
}

.editableSegmentControl > .segmentInputAccordion {
  margin-top: 16px;
  position: relative;
}

.editableSegmentControl ul {
  list-style-type: none;
  font-size: 18px;
  margin-top: 15px;
}

.editableSegmentControl li {
  display: flex;
  align-items: center;
}

/* .editableSegmentControl .card-body input[type="checkbox"] {
  visibility: none;
} */
.checkboxFrame {
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-bottom: 3px;
  margin-right: 10px;
  margin-left: -32px;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 2px;
}

.segmentInputAccordion {
  border: none;
  background: none;
  display: flex;
  flex-direction: column-reverse;
  overflow: visible;
  position: relative;
}

.segmentInputAccordion .content {
  padding: 0px;
  margin-bottom: 0 !important;
  border: solid 1px white;
  border-radius: 6px !important;
  background-color: white;
}

.segmentInputAccordion.disabled .content {
  background-color: #f2f2f2;
  border: solid 1px #f2f2f2;
  color: #626262;
  height: 38px;
}

.segmentInputAccordion.disabled .content:hover {
  border: solid 1px #f2f2f2;
}

.segmentInputAccordion .content:hover {
  border: solid 1px rgb(0, 117, 170);
}

.editableSegmentControl.dd-show {
  margin-top: 10px;
  min-height: 47px;
}

.segmentInputAccordion.dd-show {
  position: absolute;
  top: 6px;
  margin-top: 0;
  width: 100%;
  max-height: 62px;
}

label.dd-show {
  margin-top: -10px;
}

.segmentInputAccordion .content.dd-show {
  border: solid 1px rgb(0, 117, 170);
  border-bottom-color: white;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.segmentContainer {
  white-space: nowrap;
  overflow: hidden;
  height: 26px;
  font-size: 18px;
  outline: 0px solid transparent;
}

.segmentContainer br {
  display: none;
}

.segmentContainer .segment {
  display: inline;
  white-space: nowrap;
}

.segmentInputAccordion .content.dd-show,
.segmentInputAccordion .content.dd-show .segmentContainer,
.segmentInputAccordion .content.dd-show .segment {
  white-space: normal !important;
  overflow: auto;
  height: fit-content;
}

.segmentInputAccordion .dropdownWrapper .dropdown {
  position: absolute;
  width: 100%;
  border: solid 1px rgb(0, 117, 170);
  background-color: white;
  /* border-top: none; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 11;
}

.segmentInputAccordion .dropdownWrapper .dropdown label {
  margin-bottom: 0;
}

.segmentInputAccordion .dropdownWrapper .dropdown.show {
  visibility: visible;
}

.segmentInputAccordion .dropdownWrapper .dropdown.hide {
  visibility: collapse;
}

.segmentContainerWrapper {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.segmentContainerWrapper button:focus {
  box-shadow: none;
}

.segmentContainerWrapper .downArrowWrapper {
  cursor: pointer;
}

.segmentSep {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.NoSegmentWrapper {
  padding-top: 14px;
  padding-bottom: 14px;
  color: gray;
  pointer-events: none;
  background-color: transparent;
  padding-left: 16px;
  font-size: 13.7143px;
  text-transform: uppercase;
}

/*
.segmentContainerWrapper > .arrow {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 0.8571428571428571rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
}
*/

.segmentContainer {
  white-space: nowrap;
  overflow: hidden;
}

.segment.edit {
  border-bottom: solid 1px rgb(0, 117, 170) !important;
}

.segment::selection {
  background: lightgrey;
}

.MuiTooltip-arrow::before {
  box-shadow: 2px 2px 0px -1px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

@media (min-width: 600px) {
  .MuiContainer-fixed {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 960px) {
  .MuiContainer-fixed {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-fixed {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1920px) {
  .MuiContainer-fixed {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 0px) {
  .MuiContainer-maxWidthXs {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 600px) {
  .MuiContainer-maxWidthSm {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 960px) {
  .MuiContainer-maxWidthMd {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1920px) {
  .MuiContainer-maxWidthXl {
    max-width: var(--bodyWidth) !important;
  }
}
